import React, { Component } from 'react';

import { Container } from 'react-bootstrap'

import ConversationBlock from './ConversationBlock';

class Conversation extends Component {
    constructor(props) {
        super(props);


        this.state = {
            next_block: this.addConversation.bind(this),
            conversations: [ "main" ]
        };
    }

    addConversation = (name) => {
        this.setState({
            conversations: [...this.state.conversations, name]
        });
    };


    render() {
        const { conversations, next_block } = this.state;


        const conversationBlocks = conversations.map((conversation) => (
            <ConversationBlock
                conversation={conversation}
                key={conversation}
                id={conversation}
                used_conversations={conversations}
                questionCallback={next_block}
            />
        ));

        return (
            <Container className="card-group">
                {conversationBlocks}
            </Container>
        );
    }
}

export default Conversation;
