import './App.css';

import { Container } from 'react-bootstrap'

import Conversation from './components/Conversation';

function App() {
  return (
    <Container className="App p-3 d-flex justify-content-center text-center bg-light">
        <Conversation />
    </Container>
  );
}

export default App;
