import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';

import Question from './Question';
let conversations = require('../conversations.json');

class ConversationBlock extends Component {
    constructor(props) {
        super(props);

        let conversation = "main"

        if (props.conversation) {
            conversation = props.conversation;
        }

        let c = conversations[conversation];

        this.state = {
            has_selection: false,
            cardRef:  React.createRef(null),
            name: conversation,
            callback: props.questionCallback,
            header: c.header,
            paragraphs: c.paragraphs,
            questions: c.questions,
        };
    }

    componentDidMount() {
        let { cardRef } = this.state;
        cardRef.current.scrollIntoView({ behavior: "smooth"});
    }

    question_selected = (name) => {
        const { questionCallback } = this.props;
        let has_selection = this.state.has_selection;
        if (has_selection) { return }

        this.setState({has_selection: true});
        questionCallback(name);
    }

    render = () => {
        const { has_selection, cardRef, name, header, paragraphs, questions } = this.state;
        const { used_conversations } = this.props;

        const cardText = paragraphs.map((paragraph) => (
            <Card.Text className="paraphraphs">{paragraph}</Card.Text>
        ));

        const questionButtons = questions.map((question) => (
            <Question
                text={question.text}
                conversation={question.conversation}
                questionCallback={(name) => this.question_selected(name)}
                used_conversations={used_conversations}
                hasSelection={has_selection}
            />
        ));

        return (
            <Card
                id={name}
                className="mx-auto col-11 conversation-block"
                ref={cardRef}
            >
                <Card.Body>
                    <Card.Title className="header">{header}</Card.Title>
                    {cardText}
                    {questionButtons}
                </Card.Body>
            </Card>
        );
    }
}

ConversationBlock.propTypes = {
    conversation: PropTypes.string,
    questionCallback: PropTypes.func.isRequired,
    used_conversations: PropTypes.array.isRequired
};

export default ConversationBlock;
