import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Button
} from 'react-bootstrap';

class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_clicked: false
        };
    }

    handleClick = () => {
        const { conversation, questionCallback, is_clicked } = this.props;

        if (!is_clicked) {
            this.setState({is_clicked: true});

            questionCallback(conversation);
        }

    };

    render = () => {
        const { conversation, text, hasSelection, used_conversations } = this.props;
        const { is_clicked } = this.state;

        if (!is_clicked) {
            if (hasSelection) { return }
            if (used_conversations.includes(conversation)) { return }
        }

        let variant = "primary";

        if (is_clicked) {
            variant = "success";
        }

        return (
            <Button
                variant={variant}
                className="question-button"
                key={conversation}
                onClick={() => this.handleClick()}
            >
                {text}
            </Button>
        );
    }
}

Question.propTypes = {
    text: PropTypes.string.isRequired,
    conversation: PropTypes.string.isRequired,
    questionCallback: PropTypes.func.isRequired,
    hasSelection: PropTypes.bool.isRequired,
    used_conversations: PropTypes.array.isRequired
};

export default Question;
